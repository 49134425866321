// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query File($id: String!) {
    file(id: $id) {
      content,
      filename
    }
  }
`;

export default QUERY;
